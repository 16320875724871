import React from "react";

import LogoCade from "~/assets/images/logo/logo.png";

import { Container } from "./styles";

export default function Logo() {
  return (
    <Container>
      <img width="200" src={LogoCade} alt="C.A.D.Ê" />
    </Container>
  );
}
