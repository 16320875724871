import React, { useEffect, useState } from "react";
import { Tabs } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import { FaSignOutAlt, FaBarcode, FaSyncAlt } from "react-icons/fa";
import { toast } from "react-toastify";

import {
  Container,
  Menu,
  Volumes,
  Delivery,
  VolumesQuantity,
  Vehicle,
  ToDeliver,
  Delivered,
  DeliveryList,
  Header,
  TitleLine,
  PageTitle,
  FilaOffline,
  DataLine,
  MenuLink,
  OfflineIndicator,
  OnlineIndicator,
  OffLineFooter,
  OfflineText,
  HeaderTabs,
  HeaderTab,
  CustomSwipeableViews,
  SearchContainer,
  InputContainer,
  SearchIcon,
  Input,
} from "./styles";
import DeliveryCard from "./components/DeliveryCard";
import { WatchLocationManager } from "~/components/WatchLocation";
import history from "~/services/history";

import { signOut, redirectToMain } from "~/store/modules/auth/actions";
import {
  getWatchLocationSuccess,
  getWatchLocationError,
} from "~/store/modules/geolocation/actions";

import packageJson from "../../../package.json";
import ImageViewer from "../../components/ImageViewer/index";
import Online from "~/components/connection/Online";
import Offline from "~/components/connection/Offline";
import { filterDelivery } from "./helper";

export default function Main() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [filteredDelivered, setFilteredDelivered] = useState([]);
  const [filteredToDeliver, setFilteredToDeliver] = useState([]);
  const { vehicle } = useSelector(state => state.user.user);
  const { isTransporter } = useSelector(state => state.user.user);
  const { queue } = useSelector(states => states.queue);
  const [_, setOnline] = useState(true);

  const pendingItem = queue.length > 0;

  const { qtEntregas, qtVolumes } = useSelector(
    state => state.destinations.destinationsInfo.info
  );

  const { toDeliver } = useSelector(
    state => state.destinations.destinationsToDeliver
  );

  const { delivered } = useSelector(
    state => state.destinations.destinationsDelivered
  );

  const showVehicle = vehicle !== 0;

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const filteredDelivered = filterDelivery(delivered, search);
    const filteredToDeliver = filterDelivery(toDeliver, search);
    setFilteredDelivered(filteredDelivered);
    setFilteredToDeliver(filteredToDeliver);
  }, [search, delivered, toDeliver]);

  function handleBarCode() {
    history.push("/barcode");
  }

  function handleRefresh() {
    if (!pendingItem) {
      dispatch(redirectToMain());
    }
  }

  function handleSignOut() {
    if (!pendingItem) {
      dispatch(signOut());
    }
  }

  function handleChangeIndex(newIndex) {
    setIndex(newIndex);
  }

  function handleSelect(newIndex) {
    setIndex(newIndex);
  }

  function watchSuccess(position) {
    dispatch(getWatchLocationSuccess(position));
  }

  function watchError(positionError) {
    dispatch(getWatchLocationError(positionError));
  }

  function handleSearch(e) {
    setSearch(e.target.value);
  }

  return (
    <Container>
      <Header>
        <TitleLine>
          <PageTitle>Lista de Entregas - {packageJson.version}</PageTitle>
          <Menu>
            {isTransporter && (
              <MenuLink>
                <FaBarcode onClick={handleBarCode} />
              </MenuLink>
            )}
            <Online onChange={setOnline}>
              <MenuLink disabled={pendingItem}>
                <FaSyncAlt
                  opacity={pendingItem ? 0.5 : 1}
                  onClick={handleRefresh}
                />
              </MenuLink>
              <MenuLink disabled={pendingItem}>
                <FaSignOutAlt
                  opacity={pendingItem ? 0.5 : 1}
                  onClick={handleSignOut}
                />
              </MenuLink>
            </Online>
            <Offline>
              <MenuLink>
                <FaSyncAlt opacity="0.5" />
              </MenuLink>
              <MenuLink>
                <FaSignOutAlt opacity="0.5" />
              </MenuLink>
            </Offline>
          </Menu>
        </TitleLine>
        <DataLine>
          <div>
            <Volumes>
              <Delivery>Entregas realizadas - {qtEntregas}</Delivery>
              <VolumesQuantity>Volumes entregues - {qtVolumes}</VolumesQuantity>
            </Volumes>
            {showVehicle ? <Vehicle>Placa - {vehicle} </Vehicle> : ""}
          </div>
          <FilaOffline>
            Fila offline: {queue.length}
            <Online>
              <OnlineIndicator />
            </Online>
            <Offline>
              <OfflineIndicator />
            </Offline>
          </FilaOffline>
        </DataLine>
      </Header>
      <Tabs
        selectedIndex={index}
        onSelect={handleSelect}
        selectedTabClassName="active"
      >
        <HeaderTabs>
          <HeaderTab>
            <ToDeliver>A Entregar</ToDeliver>
          </HeaderTab>
          <HeaderTab>
            <Delivered>Entregues</Delivered>
          </HeaderTab>
        </HeaderTabs>
        <SearchContainer>
          <InputContainer>
            <SearchIcon />
            <Input
              placeholder="número da nota fiscal"
              type="text"
              onChange={e => handleSearch(e)}
              value={search}
            />
          </InputContainer>
        </SearchContainer>
        <CustomSwipeableViews
          index={index}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents
        >
          <DeliveryList>
            <div>
              {filteredToDeliver.map(toDeliverItem => (
                <DeliveryCard
                  key={toDeliverItem.sequencial}
                  item={toDeliverItem}
                />
              ))}
            </div>
          </DeliveryList>
          <DeliveryList>
            <div>
              {filteredDelivered.map(deliveredItem => (
                <DeliveryCard
                  key={deliveredItem.sequencial}
                  item={deliveredItem}
                />
              ))}
            </div>
          </DeliveryList>
        </CustomSwipeableViews>
      </Tabs>
      <Offline>
        <OffLineFooter>
          <OfflineText>
            Você está offline, mas pode continuar efetuando suas entregas. Assim
            que conectar será sincronizado com o servidor.
          </OfflineText>
        </OffLineFooter>
      </Offline>
      <WatchLocationManager success={watchSuccess} error={watchError} />
      <ImageViewer />
    </Container>
  );
}
const SearchBar = ({ value, handleSearch }) => {
  const handle = e => {
    handleSearch(e.target.value);
  };
  return (
    <SearchContainer>
      <InputContainer>
        <SearchIcon />
        <Input type="text" onChange={e => handle(e)} value={value} />
      </InputContainer>
    </SearchContainer>
  );
};
