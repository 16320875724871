import {
  takeLatest,
  call,
  put,
  all,
  select,
  takeEvery,
} from "redux-saga/effects";
import { toast } from "react-toastify";

import api from "~/services/api";
import { setDelivered } from "~/store/modules/destinations/actions";
import { sendToSentry, sendStoredEvents } from "~/sentry";

import {
  Actions,
  addToQueue,
  removeFromQueue,
  setLoadingSendingQueue,
} from "./actions";
import { redirectToMain } from "../auth/actions";
import { dataURLToBlob } from "~/utils/blob_to_base64";
import { setConnectionStatus } from "../connection/actions";

export function* Queue() {
  const { Connected } = yield select(state => state.connection);
  try {
    const { token } = yield select(state => state.auth);
    const { queue } = yield select(state => state.queue);
    api.defaults.headers.Authorization = `Bearer ${token}`;
    if (Connected && queue.length > 0) {
      yield* queue.map(function* setDelivery(delivery) {
        let deliveryData = {
          payload: {
            delivery,
          },
        };
        yield* Send(deliveryData);
      });
      //send stored events to sentry
      sendStoredEvents();
    } else {
      // console.tron.error("OFF-LINE OR EMPTY");
    }
  } catch (err) {
    const sentryMessage = {
      message: "error on *Queue",
      error: err.message,
      jsonError: JSON.stringify(err),
    };
    sendToSentry(sentryMessage, Connected);
    toast.error("Não efetuou as entregas");
  }
  yield put(setLoadingSendingQueue(false));
}

export function* setDeliveredItem({ payload }) {
  yield put(setDelivered(payload.delivery));
}

export function* Send(data) {
  const delivery = data?.payload?.delivery;
  yield put(setLoadingSendingQueue(true));
  try {
    const { token } = yield select(state => state.auth);
    api.defaults.headers.Authorization = `Bearer ${token}`;

    if (delivery) {
      let deliveryData = {};

      deliveryData = {
        sqEntrega: delivery.sequencial,
        dtEntrega: delivery.dataEntrega,
        idEntregue: "S",
        dsObservacao: delivery.observacao,
        dsGeoCoordenadaEntrega: {
          latitude: delivery.geoCoordenadaEntrega.latitude,
          longitude: delivery.geoCoordenadaEntrega.longitude,
        },
      };

      const retorno = yield call(api.put, "/entregas", deliveryData);

      let delivered = delivery;
      let retornoUpload = false;
      if (delivery.picture) {
        const blob = dataURLToBlob(delivery.picture);
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("sqEntrega", delivery.sequencial);
        formData.append("idTipoEnvio", "M");
        retornoUpload = yield call(api.post, "/files", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (retornoUpload.status !== 200) {
          toast.error(
            "Não foi possível enviar a imagem para o servidor! Tente novamente mais tarde"
          );
        }
      }

      if (retorno) {
        if (retorno.data) {
          if (retorno.data.returnCode === 0) {
            delivered = JSON.parse(JSON.stringify(delivery));
            delivered.entregue = "S";
          }

          yield put(removeFromQueue(delivered));
        }
        if (delivery.picture && retornoUpload) {
          yield put(redirectToMain());
        }
      } else {
        toast.error(
          "O servidor não respondeu a solicitação! tente novamente mais tarde"
        );
      }
    }
    yield put(setLoadingSendingQueue(false));
  } catch (err) {
    const sentryMessage = {
      message: "error on *Send",
      error: err.message,
      jsonError: JSON.stringify(err),
    };
    sendToSentry(sentryMessage, true);
    yield put(setLoadingSendingQueue(false));
    if (
      err.message ===
        "Cannot destructure property 'status' of 'e.response' as it is undefined." ||
      err.message.includes(`Cannot destructure`)
    ) {
      toast.error(
        "conexão fraca, entrega adicionada na fila e sera sincronizada quando a conexão for estabelecida"
      );
      yield put(setConnectionStatus(false));
      yield put(addToQueue(delivery));
      yield put(setDelivered(delivery));
    }
  }
}

export default all([
  takeEvery("@connection/CONNECTION_STATUS", Queue),
  takeLatest(Actions.REMOVE_FROM_QUEUE, setDeliveredItem),
  takeLatest(Actions.FORCE_SEND, Send),
  takeLatest(Actions.FORCE_QUEUE_CHECK, Queue),
]);
