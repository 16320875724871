import React, { useState } from "react";

import { PrimaryButton } from "../PrimaryButton";
import Camera from "./Camera";
import {
  AdditionalErrorMessage,
  ErrorMessage,
  ErrorMessageWrapper,
} from "./styles";

// eslint-disable-next-line react/prop-types
const PictureButton = ({ onSave, title, onError }) => {
  const [showCamera, setShowCamera] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <>
      <PrimaryButton
        type="button"
        onClick={() => {
          setShowCamera(true);
        }}
      >
        {title}
      </PrimaryButton>

      {errorMessage && (
        <ErrorMessageWrapper>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <AdditionalErrorMessage>
            É necessário permissão para o acesso à câmera. Verifique as
            permissões nas configurações.
          </AdditionalErrorMessage>
        </ErrorMessageWrapper>
      )}

      {showCamera && (
        <Camera
          onSave={onSave}
          onClose={() => {
            setShowCamera(false);
          }}
          onError={message => {
            onError();
            setErrorMessage(message);
            setShowCamera(false);
          }}
        />
      )}
    </>
  );
};

export default PictureButton;
