import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { format } from "date-fns";
import { toast } from "react-toastify";

import ConfirmationModal from "./components/ConfirmationModal";
import GpsUnavailableModal from "./components/GpsUnavailableModal";
import {
  CustomModalTitle,
  VolumeList,
  DisabledGpsMessage,
  VolumeListItem,
} from "./styles";

import { ClearButton } from "~/components/ClearButton";
import { PrimaryButton } from "~/components/PrimaryButton";
import {
  deliveredPackageAction,
  setLoadingSendingQueue,
} from "~/store/modules/queue/actions";
import { updateDestinationsInfo } from "~/store/modules/destinations/actions";
import {
  clearCurrentLocation,
  getCurrentLocationRequest,
} from "~/store/modules/geolocation/actions";
import PictureButton from "~/components/PictureButton";
import { PreviewImg } from "../../components/PreviewImg";
import RequireImageModal from "./components/RequireImageModal";
import { getBase64FromBlob } from "~/utils/blob_to_base64";
import Spinner from "~/components/Spinner";
import { sendToSentry } from "~/sentry";

export default function Destination(props) {
  const dispatch = useDispatch();
  const { item, show, onHide } = props;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRequireImageModal, setShowRequireImageModal] = useState(false);

  const [picture, setPicture] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");
  const [cameraError, setCameraError] = useState(false);

  const connected = useSelector(state => state.connection.Connected);
  const { coords, lastCoords } = useSelector(states => states.geolocation);
  const geolocationEnabled = useSelector(
    states => states.geolocation.geolocationEnabled
  );

  function handleValidateLocationAndOpenConfirm() {
    if (!cameraError && !picture) {
      setShowRequireImageModal(true);
      toast.error(
        "Não foi inserida uma imagem, confere a sua pasta de downloads"
      );
    }
    if (!picture) {
      setShowRequireImageModal(true);
      toast.error("Não foi inserida uma imagem");
    } else {
      dispatch(clearCurrentLocation());
      dispatch(getCurrentLocationRequest());
      setShowConfirmModal(true);
    }
  }

  function handleSavePicture(blob, filename) {
    setPicture({ blob, filename });
    setPictureUrl(URL.createObjectURL(blob));
  }

  function handleCameraError() {
    setCameraError(true);
    const event = {
      message: "opening camera error",
    };
    sendToSentry(event, connected);
  }

  const dtEntrega = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxx");

  async function handleConfirmDeliverySent() {
    // dispatch(setLoadingSendingQueue(true));
    let latitude;
    let longitude;
    if (!coords) {
      if (!lastCoords) {
        latitude = "";
        longitude = "";
      } else {
        latitude = lastCoords.latitude;
        longitude = lastCoords.longitude;
      }
    } else {
      latitude = coords.latitude;
      longitude = coords.longitude;
    }
    const imageBase64 = await getBase64FromBlob(picture.blob);
    const itemToSend = {
      ...item,
      geoCoordenadaEntrega: { latitude, longitude },
      dataEntrega: dtEntrega,
      entregue: "S",
      picture: imageBase64,
    };
    deliveredPackageAction(itemToSend, dispatch, connected);
    dispatch(updateDestinationsInfo());
    onHide();
  }

  return (
    <>
      <Modal
        onHide={onHide}
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <CustomModalTitle id="contained-modal-title-vcenter">
            {item.nomeComercialFantasia.nomeFantasia}
          </CustomModalTitle>
        </Modal.Header>
        <Modal.Body>
          <p>
            {item.volumes} {`volume${item.volumes > 1 ? "s" : ""}`}
            <br />
            CNPJ: {item.cnpj}{" "}
          </p>
          <p>
            Endereço para entrega:{" "}
            {`CEP: ${item.enderecoEntrega.cep}
            Rua: ${item.enderecoEntrega.rua},
            ${item.enderecoEntrega.numero},
            Bairro: ${item.enderecoEntrega.bairro},
            Cidade: ${item.enderecoEntrega.cidade} -
            ${item.enderecoEntrega.estado}`}
          </p>
          <span>Notas fiscais:</span>
          <VolumeList>
            {item.nfes.map(nfe => (
              <VolumeListItem key={nfe.numero}>
                {nfe.numero} - {nfe.volumes}{" "}
                {`volume${nfe.volumes > 1 ? "s" : ""}`} - Tipo:{" "}
                {nfe.tipoNota === "V" ? "Venda" : "Bonificada"}
              </VolumeListItem>
            ))}
          </VolumeList>
          GPS {geolocationEnabled ? "A" : "Ina"}tivo
          {!geolocationEnabled && ": "}
          {!geolocationEnabled && (
            <DisabledGpsMessage>
              Por favor, verifique se o seu GPs está ativado ou vá para outro
              local para melhorar o sinal.
            </DisabledGpsMessage>
          )}
          <>
            <span>Anexar imagem:</span>
            <br />
            <PictureButton
              title="Abrir câmera"
              onSave={handleSavePicture}
              onError={handleCameraError}
            />
            <br />
            {picture && (
              <PreviewImg src={pictureUrl} alt="Preview imagem anexada" />
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <ClearButton onClick={onHide}>CANCELAR</ClearButton>
          <PrimaryButton onClick={handleValidateLocationAndOpenConfirm}>
            CONFIRMAR ENTREGA{" "}
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
      <>
        <RequireImageModal
          show={showRequireImageModal}
          setShow={setShowRequireImageModal}
        />
      </>
      <>
        {geolocationEnabled ? (
          <ConfirmationModal
            show={showConfirmModal}
            setShow={setShowConfirmModal}
            handleConfirmDeliverySent={handleConfirmDeliverySent}
          />
        ) : (
          <GpsUnavailableModal
            show={showConfirmModal}
            setShow={setShowConfirmModal}
            handleConfirmDeliverySent={handleConfirmDeliverySent}
          />
        )}
      </>
    </>
  );
}

Destination.propTypes = {
  item: PropTypes.shape({
    nfes: PropTypes.arrayOf(
      PropTypes.shape({
        numero: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ).isRequired,
    enderecoEntrega: PropTypes.shape({
      rua: PropTypes.string.isRequired,
      bairro: PropTypes.string.isRequired,
      cidade: PropTypes.string.isRequired,
      estado: PropTypes.string.isRequired,
      cep: PropTypes.string.isRequired,
      numero: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    }).isRequired,
    nomeComercialFantasia: PropTypes.shape({
      razaoSocial: PropTypes.string.isRequired,
      nomeFantasia: PropTypes.string.isRequired,
    }).isRequired,
    entregue: PropTypes.string.isRequired,
    volumes: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    cnpj: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    geoCoordenadaEntrega: PropTypes.shape({
      latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    idExigeFotoEntrega: PropTypes.string,
  }).isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
