import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

export const LoaderWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.05;
  background-color: rgba(0, 0, 0, 0.05);
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: auto;
`;

export const LoaderSpinner = styled(Spinner)`
  color: #fff;
  width: 5rem;
  height: 5rem;
`;
