import styled from "styled-components";
import { Tab, TabList } from "react-tabs";
import SwipeableViews from "react-swipeable-views";
import { FaSearch } from "react-icons/fa";

export const Container = styled.div``;

export const Header = styled.div`
  background-color: #143c96;
  color: #fff;
`;

export const TitleLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 0;
`;

export const PageTitle = styled.div`
  font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 5px;
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
`;

export const MenuLink = styled.span`
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-size: 1.1em;
  margin: 5px;
  text-align: center;
`;

export const DataLine = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 0.9em;
`;

export const FilaOffline = styled.div`
  padding: 0 10px;
`;

export const Volumes = styled.div`
  padding: 0 10px;
`;

export const Vehicle = styled.div`
  padding: 0 10px;
`;

export const Delivery = styled.div``;

export const VolumesQuantity = styled.div``;

export const ToDeliver = styled.div`
  padding: 10px 0;
`;

export const Delivered = styled.div`
  padding: 10px 0;
`;

const ConnectionIndicator = styled.div`
  margin-left: 0.3em;
  width: 0.9em;
  height: 0.9em;
  border-radius: 50%;
  display: inline-block;
`;

export const OnlineIndicator = styled(ConnectionIndicator)`
  background-color: #03fc17;
`;
export const OfflineIndicator = styled(ConnectionIndicator)`
  background-color: #ff0808;
`;

export const OffLineFooter = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  color: #fff;
  background-color: #e37a7a;
`;

export const OfflineText = styled.p`
  text-align: center;
  padding: 5px;
`;

export const DeliveryList = styled.div`
  padding-bottom: 100px;
`;

export const HeaderTabs = styled(TabList)`
  display: flex;
  font-size: 1.1em;
  width: 100%;
  background-color: #143c96;
  box-shadow: inset 0 8px 5px -7px rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
`;

export const HeaderTab = styled(Tab)`
  width: 50%;
  list-style-type: none;
  text-align: center;
  color: #fff;
  margin-bottom: 2px;

  &.active {
    margin: 0;
    border-bottom: #fff solid 2px;
  }
`;

export const CustomSwipeableViews = styled(SwipeableViews)`
  box-shadow: inset 0 8px 5px -7px rgba(0, 0, 0, 0.5);
`;
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  padding-left: 5px;
  height: 60px;
`;
export const SearchIcon = styled(FaSearch)`
  margin-right: 5px;
`;
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin-right: 5px;
  padding-left: 10px;
  width: 100%;
  height: 40px;
  background-color: white;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  flex: 1;
  padding: 0 5px;
  width: 100%;
  height: 30px;
  border-radius: 15px;
  ::placeholder {
    color: #999;
    opacity: 0.5;
  }
`;
