import styled from "styled-components";

export const Container = styled.div`
  max-width: 300px;
  max-height: 4500px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 80px auto;
  align-items: center;
`;

export const Form = styled.form`
  margin-top: 30px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  input {
    flex: 1;
    border: 1px solid #eee;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 16px;
  }
  .add-button {
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #ff3300;
    color: #efefef;
    font-weight: bold;
    border: 0px;
    font-size: 16px;
  }
`;
export const TextError = styled.span`
  padding: 10px;
  margin: 20px auto;
  color: #e37a7a;
  font-weight: bold;
  font-size: 0.7em;
`;

export const InputGroup = styled.div`
  display: flex;
  margin: 5px 0;
  align-items: center;
`;

export const SubmitButton = styled.button.attrs({
  type: "submit",
})`
  flex: 1;
  border: 1px solid #eee;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  color: #fff;
  background-color: #143c96;
`;

export const Footer = styled.span`
  max-width: 300px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 80px auto;
`;
