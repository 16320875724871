import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";

import { getCurrentLocationRequest } from "~/store/modules/geolocation/actions";
import { PrimaryButton } from "~/components/PrimaryButton";
import history from "~/services/history";

export default function Geolocation() {
  const dispatch = useDispatch();
  const { fetched, error } = useSelector(states => states.geolocation);

  const geolocationEnabled = useSelector(
    states => states.geolocation.geolocationEnabled
  );

  if (error) {
    // toast(error, {
    //   hideProgressBar: true,
    // });
  }

  if (fetched || geolocationEnabled) {
    history.push("/main");
  }

  function handleActivateGeolocation() {
    dispatch(getCurrentLocationRequest());
  }

  return (
    !fetched &&
    !geolocationEnabled && (
      <Modal show centered>
        <Modal.Header>
          <Modal.Title>Habilite a geolocalização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            O aplicativo precisa do acesso a localização. Clique no botão
            habilitar e logo em seguida no botão permitir para que o aplicativo
            tenha acesso a sua localização.
          </p>
          <PrimaryButton onClick={handleActivateGeolocation}>
            Habilitar
          </PrimaryButton>
        </Modal.Body>
      </Modal>
    )
  );
}
