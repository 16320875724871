import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  Container,
  CardTitle,
  CardContent,
  CardActions,
  CardTitleContainer,
  CardTitleClientCode,
  ContentLowOpacity,
} from "./styles";

import Destination from "~/pages/Destinations";
import Observation from "~/pages/Observations";
import { ClearButton } from "~/components/ClearButton";
import { PrimaryButton } from "~/components/PrimaryButton";
import Image from "~/components/Image";
import { PreviewImg } from "~/components/PreviewImg";
import { sendFile } from "~/store/modules/destinations/actions";
import { Img } from "~/components/Image/style";

export default function DeliveryCard({ item }) {
  const dispatch = useDispatch();

  const [modalDeliveryShow, setModalDeliveryShow] = useState(false);
  const [modalObservationShow, setModalObservationShow] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const { queue } = useSelector(state => state.queue);
  const { loadingSendFile } = useSelector(state => state.destinations);
  const onQueueItem = queue.find(
    delivery => delivery.sequencial === item.sequencial
  );

  const onQueue = onQueueItem !== undefined;

  const [picture, setPicture] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");

  const fileToDataUri = file =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  function onFileUpload(e) {
    const file = e.target.files[0];
    if (!file) {
      setPictureUrl("");
      return;
    }

    const filename = `cade-${Math.floor(Date.now() / 1000)}.png`;

    setPicture({ file, filename });
    fileToDataUri(file).then(dataUri => {
      setPictureUrl(dataUri);
    });
  }

  function sendImage() {
    const type = item.idExigeFotoEntrega === "S" ? "M" : null;
    dispatch(
      sendFile({ file: picture.file, sequencial: item.sequencial, tipo: type })
    );
  }

  const ImageArea = () => {
    if (item.entregue !== "S") {
      return null;
    }

    if (item.entregue === "S" && onQueue) {
      return (
        <CardContent>
          <Img src={onQueueItem.picture} />
        </CardContent>
      );
    }
    if (item.file) {
      const folder = "S" ? "minutas" : "entrega";
      const imageUrl = `imagens/${item.cdCliente}/${item.sequencial}/${folder}/${item.file}`;
      return (
        <CardContent>
          {showImage ? (
            <Image image={imageUrl} />
          ) : (
            <div>
              Imagem já enviada ao servidor{" "}
              <button
                onClick={() => {
                  setShowImage(true);
                }}
              >
                Mostrar imagem
              </button>
            </div>
          )}{" "}
        </CardContent>
      );
    }
    return (
      <>
        <CardContent>Anexar imagem:</CardContent>
        <CardContent>
          <input type="file" onChange={onFileUpload} />
        </CardContent>

        {picture && (
          <>
            <PreviewImg src={pictureUrl} alt="Preview imagem anexada" />
            <CardActions>
              <PrimaryButton
                type="button"
                onClick={() => {
                  sendImage();
                }}
              >
                {loadingSendFile ? "Enviando" : "Enviar imagem"}
              </PrimaryButton>
            </CardActions>
          </>
        )}
      </>
    );
  };
  const uniqueDates = () => {
    const listOfDates = [];
    for (let nfe of item.nfes) {
      if (nfe.dataNota) {
        listOfDates.push(nfe.dataNota);
      }
    }
    if (!listOfDates.length) {
      return "-";
    }
    return listOfDates.join(", ");
  };

  return (
    <Container>
      <CardTitleContainer>
        <CardTitleClientCode>
          {item.cdCliente}
          {item.nrDvCliente}
        </CardTitleClientCode>
        -<CardTitle>{item.nomeComercialFantasia.nomeFantasia}</CardTitle>
      </CardTitleContainer>
      <CardContent>
        {item.volumes} {`volume${item.volumes > 1 ? "s" : ""}`}
      </CardContent>
      <CardContent>
        Razão Social: {item.nomeComercialFantasia.razaoSocial}
      </CardContent>
      <CardContent>
        Endereço:{" "}
        {`
          ${item.enderecoEntrega.rua},
          ${item.enderecoEntrega.numero},
          ${item.enderecoEntrega.bairro},
          ${item.enderecoEntrega.cidade} -
          ${item.enderecoEntrega.estado}.
          CEP: ${item.enderecoEntrega.cep}`}
      </CardContent>
      <ContentLowOpacity>Data emissão NFe: {uniqueDates()}</ContentLowOpacity>
      <ImageArea />
      <CardActions>
        <ClearButton onClick={() => setModalObservationShow(true)}>
          Observações
        </ClearButton>
        {item.entregue === "N" && (
          <PrimaryButton
            onClick={() => {
              setModalDeliveryShow(true);
            }}
          >
            Entregar
          </PrimaryButton>
        )}
        {item.entregue === "S" && onQueue && <div>Aguardando Envio</div>}
      </CardActions>

      <>
        <Observation
          item={item}
          show={modalObservationShow}
          onHide={() => setModalObservationShow(false)}
        />

        <Destination
          item={item}
          show={modalDeliveryShow}
          onHide={() => setModalDeliveryShow(false)}
        />
      </>
    </Container>
  );
}

DeliveryCard.propTypes = {
  item: PropTypes.shape({
    enderecoEntrega: PropTypes.shape({
      rua: PropTypes.string.isRequired,
      bairro: PropTypes.string.isRequired,
      cidade: PropTypes.string.isRequired,
      estado: PropTypes.string.isRequired,
      cep: PropTypes.string.isRequired,
      numero: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    }).isRequired,
    nomeComercialFantasia: PropTypes.shape({
      razaoSocial: PropTypes.string.isRequired,
      nomeFantasia: PropTypes.string.isRequired,
    }).isRequired,
    entregue: PropTypes.string.isRequired,
    sequencial: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    volumes: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    file: PropTypes.string,
    idExigeFotoEntrega: PropTypes.string,
  }).isRequired,
};
