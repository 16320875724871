import { createGlobalStyle } from "styled-components";

import "react-toastify/dist/ReactToastify.css";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
  }

  body {
    background: #91C8DC;
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    color: #222;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
  }

  button {
    cursor: pointer;
  }

  .toast {
    & div {
      background-color: #333;
      color: #fff;
      & .Toastify__close-button--default {
        color: #fff;
        opacity: 0.7;
      }
    }
  }

  div.fade.modal.show + div.fade.modal-backdrop.show {
    z-index: 1060;
  }
`;
