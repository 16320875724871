import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { format } from "date-fns";

import { CustomModal } from "./styles";

import {
  setNewObservation,
  sendFile,
  setSendFileReturn,
} from "~/store/modules/destinations/actions";
import { ClearButton } from "~/components/ClearButton";
import { PrimaryButton } from "~/components/PrimaryButton";
import PictureButton from "~/components/PictureButton";
import { PreviewImg } from "~/components/PreviewImg";

export default function NewObservation(props) {
  const dispatch = useDispatch();
  const { item, onHide, show } = props;
  const [observacao, setObservacao] = useState("");
  const [picture, setPicture] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");
  const { sendFileReturn } = useSelector(state => state.destinations);

  const addObservation = useCallback(() => {
    let novaObservacao = observacao;
    const dtObservacao = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    const newObservation = {
      observacao: novaObservacao,
      dataObservacao: dtObservacao,
    };

    if (sendFileReturn && sendFileReturn.returnCode === 0) {
      newObservation.file = sendFileReturn.error.file.nm_arquivo_fisico;
    }
    dispatch(setSendFileReturn(null));

    if (item.observacao) {
      novaObservacao = [...item.observacao, newObservation];
    } else {
      novaObservacao = [newObservation];
    }
    const delivery = {
      ...item,
      observacao: novaObservacao,
    };
    dispatch(setNewObservation(delivery));
    onHide();
    setPicture("");
  }, [dispatch, item, observacao, onHide, sendFileReturn]);

  useEffect(() => {
    if (sendFileReturn) {
      addObservation();
    }
  }, [sendFileReturn, addObservation]);

  function handleSavePicture(blob, filename) {
    setPicture({ blob, filename });
    setPictureUrl(URL.createObjectURL(blob));
  }

  function handleAddObservation(e) {
    e.preventDefault();

    if (picture) {
      dispatch(
        sendFile({
          file: picture.blob,
          sequencial: item.sequencial,
          tipo: "O",
          noReload: true,
        })
      );
      return;
    }

    addObservation();
  }

  return (
    <CustomModal
      onHide={onHide}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {item.nomeComercialFantasia.nomeFantasia}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>Nova Observação:</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            placeholder="Digite a observação"
            value={observacao}
            aria-label="Digite a sua senha"
            onChange={e => setObservacao(e.target.value)}
          />
          <span>Anexar imagem:</span>
          <br />
          <PictureButton title="Abrir câmera" onSave={handleSavePicture} />
          <br />
          {picture && (
            <PreviewImg src={pictureUrl} alt="Preview imagem anexada" />
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <ClearButton onClick={onHide}>CANCELAR</ClearButton>
        <PrimaryButton onClick={handleAddObservation}>ENVIAR</PrimaryButton>
      </Modal.Footer>
    </CustomModal>
  );
}

NewObservation.propTypes = {
  item: PropTypes.shape({
    nfes: PropTypes.arrayOf(
      PropTypes.shape({
        numero: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }).isRequired
    ),
    observacao: PropTypes.arrayOf(
      PropTypes.shape({
        numero: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }).isRequired
    ),

    enderecoEntrega: PropTypes.shape({
      rua: PropTypes.string.isRequired,
      bairro: PropTypes.string.isRequired,
      cidade: PropTypes.string.isRequired,
      estado: PropTypes.string.isRequired,
      cep: PropTypes.string.isRequired,
      numero: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    }).isRequired,
    nomeComercialFantasia: PropTypes.shape({
      razaoSocial: PropTypes.string.isRequired,
      nomeFantasia: PropTypes.string.isRequired,
    }).isRequired,
    entregue: PropTypes.string.isRequired,
    volumes: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    cnpj: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    sequencial: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
  }).isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
