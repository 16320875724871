import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaUser, FaKey } from "react-icons/fa";

import { Container, Form, SubmitButton, TextError, InputGroup } from "./styles";

import { signInRequest } from "~/store/modules/auth/actions";
import Logo from "~/components/Logo";
import Footer from "~/components/Footer";

export default function Login() {
  const dispatch = useDispatch();
  const { loading, errorMessage } = useSelector(state => state.auth);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(signInRequest(login, password));
  }

  return (
    <Container>
      <Logo />
      <Form onSubmit={handleSubmit}>
        <InputGroup>
          <FaUser />{" "}
          <input
            type="text"
            placeholder="Digite o seu login"
            value={login}
            aria-label="Digite o seu login"
            onChange={e => setLogin(e.target.value)}
          />
        </InputGroup>
        <InputGroup>
          <FaKey />{" "}
          <input
            type="password"
            placeholder="Digite a sua senha"
            value={password}
            aria-label="Digite a sua senha"
            onChange={e => setPassword(e.target.value)}
          />
        </InputGroup>
        <TextError>{errorMessage}&nbsp;</TextError>
        <SubmitButton>{loading ? "ENTRANDO..." : "ENTRAR"}</SubmitButton>
        <button className="add-button" type="button">
          Adicionar a página principal
        </button>
      </Form>
      <Footer />
    </Container>
  );
}
